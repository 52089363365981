/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans"
import "@fontsource/open-sans/600.css"
